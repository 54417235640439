import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Icon from "../../../../../components/icon"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"

import ManuskriptAndenken from "../../../../../content/kurse/reiseerinnerung/02-dichterwerkstatt/manuskript-andenken"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/02-dichterwerkstatt/hoelderlins-handschrift" />
      }
      navigation={navigation}
    >
      <Seo title="Das Manuskript erforschen" />
      <Stack>
        <Heading as="h2" level={2}>
          Das Manuskript erforschen
        </Heading>
        <Paragraph>
          Von dem handschriftlichen Entwurf des Gedichts ist lediglich die
          letzte Strophe erhalten geblieben.
        </Paragraph>

        <TaskSidebar
          main={<ManuskriptAndenken />}
          sidebar={
            <Note variant="task">
              <>
                Was kann man aus dieser Handschrift erfahren? Sieh dir das
                Manuskript genauer an und inspiziere die mit dem
                <Box
                  as="span"
                  display="inline-flex"
                  ml={1}
                  bg="primary"
                  color="background"
                  borderRadius="full"
                >
                  <Icon size={4} icon="plus" />
                </Box>{" "}
                Zeichen gekennzeichneten Details.
              </>
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
