import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Blockquote from "../../../../components/blockquote"
import Box from "../../../../components/box"
import Constrain from "../../../../components/constrain"
import Paragraph from "../../../../components/paragraph"
import Heading from "../../../../components/heading"
import Image from "../../../../components/image"
import ImageHotspot from "../../../../components/image-hotspot"
import ImageHotspots from "../../../../components/image-hotspots"
import Note from "../../../../components/note"
import Poem from "../../../../components/poem"
import Stack from "../../../../components/stack"
import Text from "../../../../components/text"

const ManuskriptAndenken = (props) => {
  const data = useStaticQuery(graphql`
    query ManuskriptAndenkenQuery {
      gedicht: file(relativePath: { eq: "kurse/andenken-manuskript.png" }) {
        ...largeImage
      }
      andenken01: file(relativePath: { eq: "kurse/andenken-01.jpg" }) {
        ...largeImage
      }
      andenken02: file(relativePath: { eq: "kurse/andenken-02.jpg" }) {
        ...largeImage
      }
      andenken03: file(relativePath: { eq: "kurse/andenken-03.jpg" }) {
        ...largeImage
      }
      andenken04: file(relativePath: { eq: "kurse/andenken-04.jpg" }) {
        ...largeImage
      }
    }
  `)

  const ModalTemplate = (props) => {
    const { title, image, children } = props
    return (
      <Stack>
        <Constrain>
          <Stack>
            <Heading level={3}>{title}</Heading>
            {image}
            {children}
          </Stack>
        </Constrain>
      </Stack>
    )
  }

  return (
    <Stack space={3}>
      <ImageHotspots
        caption="Hölderlins Gedichtmanuskript ›Andenken‹ aus dem Hölderlin-Archiv, Homburg"
        image={
          <Image
            image={data.gedicht.childImageSharp.gatsbyImageData}
            alt="Handschriftliches Manuskript von Hölderlins ›Andenken‹"
          />
        }
      >
        <ImageHotspot x={23} y={26} name="andenken-01">
          <ModalTemplate
            title="Noch einmal von vorn"
            image={
              <Image
                image={data.andenken01.childImageSharp.gatsbyImageData}
                alt="vergrößerter Ausschnitt der ersten Zeilen des Gedichtes"
              />
            }
          >
            <Paragraph>
              Die auf dem Blatt überlieferte letzte Strophe des Gedichts setzt
              ein mit den Worten:
            </Paragraph>
            <Blockquote>
              <Poem>
                <p>Nach Indien sind</p>
                <p>Die Freunde gezogen</p>
              </Poem>
            </Blockquote>
            <Paragraph>
              Doch damit scheint Hölderlin noch nicht ganz zufrieden gewesen zu
              sein. Er streicht die beiden Zeilen und setzt darunter noch einmal
              neu an:
            </Paragraph>
            <Blockquote>
              <Poem>
                <p>Nun aber sind zu Indiern</p>
                <p>
                  Die Männer{" "}
                  <Box as="span" sx={{ textDecoration: "line-through" }}>
                    gezogen
                  </Box>
                  , gegangen
                </p>
              </Poem>
            </Blockquote>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={30} y={40} name="andenken-02">
          <ModalTemplate
            title="Textüberlagerungen"
            image={
              <Image
                image={data.andenken02.childImageSharp.gatsbyImageData}
                alt="Textüberlagerungen im Manuskript"
              />
            }
          >
            <Paragraph>
              An dieser Stelle hat Hölderlin bei der Überarbeitung noch einiges
              korrigiert und umformuliert. Aus den Versen »Fernhin, wo sich
              endiget / Meerbreit der Strom« wurde später »Fern, wo an der
              luftigen Spiz’ / Des Rebenlandes herrab / Die Dordogne kommt«.
            </Paragraph>

            <Paragraph>
              Solche Stellen, in denen sich verschiedene Fassungen des Gedichts
              überlagern, finden sich häufig in seinen Manuskripten. Die meisten
              Werke entwickelte er nicht linear von der ersten Zeile bis zum
              letzten Satz, sondern in Versatzstücken, die er immer wieder
              umschrieb.
            </Paragraph>

            <Note title="Übrigens">
              In der Erstveröffentlichung wurde aus der »luftigen Spiz’« die
              »lustige Spiz’«. Vermutlich hatten die Drucker auch ihre
              Schwierigkeiten mit der Entzifferung von Hölderlins Handschrift.
            </Note>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={55} y={70} name="andenken-03">
          <ModalTemplate
            title="Der letzte Vers"
            image={
              <Image
                image={data.andenken03.childImageSharp.gatsbyImageData}
                alt="Bild der letzten Zeilen des Manuskripts"
              />
            }
          >
            <Paragraph>
              Im Manuskript endet das Gedicht noch mit den Worten: »Ein
              Bleibendes aber stiften die Dichter.«
            </Paragraph>
            <Paragraph>
              Später hat Hölderlin diesen Satz zu dem etwas prägnanteren und um
              1 Silbe verkürzten Vers »Was bleibet aber stiften die Dichter«
              umformuliert.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={30} y={93} name="andenken-04">
          <ModalTemplate
            title="Atlantik trifft auf Donau"
            image={
              <Image
                image={data.andenken04.childImageSharp.gatsbyImageData}
                alt="Bild des Manuskripts. Auf dem Blatt drücken sich die Verse von der Rückseite durch"
              />
            }
          >
            <Paragraph>
              Auf dem Blatt drücken sich die Verse von Hölderlins Donau-Gedicht
              ›Der Ister‹ durch, das auf der Rückseite entstand.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
      </ImageHotspots>
      <Text align="center" color="whisper" size={1}>
        Bild: Stadt Bad Homburg v.d.Höhe, Depositum in der Württembergischen
        Landesbibliothek, Hölderlin-Archiv, Homburg H,27-28
      </Text>
    </Stack>
  )
}

export default ManuskriptAndenken
